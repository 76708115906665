import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'mdi' // default - only for display purposes
  },
  theme: {
    options: {
      customProperties: true
    },
    // dark: darkMediaQuery.matches,
    themes: {
      light: {
        primary: '#003663', // blue
        secondary: '#FFA940', // yellow

        // custom properties
        'bg': '#F7F8FA', // gray
        'primary-variant': '#1DBEB8', // green
        'secondary-variant': '#E85F5B', // red
      }
    }
  }
})
