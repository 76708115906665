<template>
  <v-snackbar
    data-cy="my-snackbar"
    class="my-snackbar mb-4"
    v-model="snackState"
    :timeout="4000"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'MySnackbar',
  props: {
    showSnack: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: null
    }
    // timeout: {
    //   type: Number,
    //   default: null
    // }
  },
  computed: {
    snackState: {
      get () {
        return this.showSnack
      },
      set () {
        this.close()
      }
    }
  },
  methods: {
    close () {
      this.snackbar.commit('hide')
    }
  }
}
</script>

<style scoped lang="scss">
.v-snack.my-snackbar {
  height: var(--vh, 100%);
}
</style>
