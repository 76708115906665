export class SessionManager {
  saveUserProfile (item) {
    sessionStorage.setItem('userProfile', JSON.stringify(item))
  }

  getUserProfile () {
    return sessionStorage.getItem('userProfile') ? JSON.parse(sessionStorage.getItem('userProfile')) : null
  }

  removeUserSession () {
    sessionStorage.removeItem('userProfile')
  }

  checkPermission (requiredPermissions) {
    const userProfile = this.getUserProfile()
    const userPermissions = new Set(userProfile.roles.reduce((role, val) => role.concat([...val.permissions]), []))
    return !!(([...new Set(requiredPermissions)].filter(x => userPermissions.has(x))).length)
  }
}
