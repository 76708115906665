<template>
  <div class="locale-switch d-flex">
    <span @click="changeLocale('th')" :class="$i18n.locale === 'th' ? 'font-weight-semi-bold secondary--text' : 'cursor-pointer'">TH</span>
    <v-divider vertical class="mx-1" style="border-color: white;" />
    <span @click="changeLocale('en')" :class="$i18n.locale === 'en' ? 'font-weight-semi-bold secondary--text' : 'cursor-pointer'">EN</span>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitch',
  methods: {
    changeLocale (lang) {
      this.$i18n.locale = lang
      localStorage.setItem('locale', lang)
      let query = Object.assign({}, this.$route.query)
      query.lang = lang
      this.$router.replace({ query })
    }
  }
}
</script>
