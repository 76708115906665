<template>
  <div class="landing-footer">
    <v-row no-gutters class="align-center px-5 py-5">
      <v-col cols="12" xs="12" sm="5" md="5">
        <div class="d-block d-md-flex align-center">
          <img class="mt-2 ml-md-10" height="95"
            :src="utility.getImgSrc('landing-header-logo@4x.png')"
            :srcset="utility.getImgSrcSet('landing-header-logo')"
            alt="Gistda platform"
          />
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="2" md="2">
        <div class="d-flex justify-center">
          <v-btn dark class="footer-logo mx-1 my-2" width="58" height="58" icon
            @click="utility.openLink('mailto:sphere@gistda.or.th')"
          >
            <v-img contain height="42" width="42" :src="utility.getImgSrc('mail-logo.svg')" alt="Mail" />
          </v-btn>
          <v-btn dark class="footer-logo mx-1 my-2" width="58" height="58" icon
          @click="utility.openLink('https://www.facebook.com/gistda')"
          >
            <v-img contain height="42" width="42" :src="utility.getImgSrc('fb-logo.svg')" alt="Mail" />
          </v-btn>
          <v-btn dark class="footer-logo mx-1 my-2" width="58" height="58" icon
          @click="utility.openLink('https://www.instagram.com/gistda_space')"
          >
            <v-img contain height="42" width="42" :src="utility.getImgSrc('ig-logo.svg')" alt="Mail" />
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" xs="12" sm="5" md="5">
        <div class="footer-text my-2 text-sm-right">
          {{ $t('landing.footerText1') }}<br>
          {{ $t('landing.footerText2') }}<br>
          {{ $t('landing.footerText3') }}<br>
          {{ $t('landing.footerText4') }} <a class="text-white text-decoration-none" href="tel:021430567">02 143 0567</a>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LandingFooter',
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/landing-footer.scss';
</style>
