<template>
  <div class="landing-header">
    <v-app-bar
      v-if="$vuetify.breakpoint && $vuetify.breakpoint.smAndDown"
      app
      mobile-breakpoint
      color="primary"
      dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="mr-5" />
      <router-link class="ma-auto" to="/">
        <img max-width="111" height="36" contain
          :src="utility.getImgSrc('logo-sphere-alt.png', true)"
          alt="sphere"
          class="mt-2"
        />
      </router-link>
      <locale-switch />
    </v-app-bar>
    <v-navigation-drawer
      app dark
      v-model="drawer"
      class="primary d-flex d-md-none"
      mobile-breakpoint
      temporary
      hide-overlay
    >
      <div class="pa-8 pb-5 text-white text-left">
        <!-- image -->
        <v-btn disabled elevation="3" color="#c3c3c3" fab height="45" width="45" class="mb-5">
          <v-img v-if="profileImg" width="25" :src="profileImg" style="border-radius: 32px;" alt="Profile Image" />
          <v-img v-else width="25" class="ma-4" :src="utility.getImgSrc('ic-account-white.svg')" alt="Profile Image" />
        </v-btn>
        <div v-if="profile && profile.id">
          <div class="one-line text-lg word-break-all font-weight-semi-bold">{{ profile.displayName || profile.username }}</div>
          <div v-if="profile.organization" class="text-sm font-weight-light one-line word-break-all">{{ profile.organization }}</div>
        </div>
        <div class="d-flex" v-else>
          <v-btn
            color="secondary-variant"
            class="text-white"
            @click="login"
          >
            Log in
          </v-btn>
          <v-btn
            outlined
            color="#FFF"
            class="ml-4"
            @click="login"
          >
            Sign up
          </v-btn>
        </div>
      </div>
      <v-divider />
      <v-list class="mt-9">
        <v-list-item
          v-for="item in navigationItems"
          :key="item.title"
          link
          class="text-white py-1 pl-7"
          @click="onNavigationClick(item)"
        >
          <v-list-item-content>
            <v-list-item-title>
              <v-icon v-if="item.icon" class="mr-2">{{ item.icon }}</v-icon>
              <span class="text-lg">{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="py-4 d-none d-md-flex">
      <router-link to="/" :class="$route.name === 'landing' ? 'd-none':''">
        <img class="ml-8 ml-lg-15 mt-2" height="85" contain
          :src="utility.getImgSrc('logo-sphere-alt.png', true)"
          alt="sphere"
        />
      </router-link>
    </div>
    <div class="landing-navigation d-none d-md-flex align-center mr-8 mr-lg-15 text-lg">
      <div class="mx-2 mx-lg-4" v-for="item in navigationItems" :key="item.title">
        <a class="navigation-link" @click="onNavigationClick(item)">
          <v-icon v-if="item.icon" color="#FFF">mdi-help-circle-outline</v-icon>
          {{ item.title }}
        </a>
      </div>
      <locale-switch />
      <div v-if="profile && profile.id" class="user-panel d-flex align-center">
        <div class="ml-8">
          <!-- image -->
          <v-btn disabled elevation="3" color="#c3c3c3" fab height="45" width="45">
            <v-img v-if="profileImg" width="25" :src="profileImg" style="border-radius: 32px;" alt="Profile Image" />
            <v-img v-else width="25" class="ma-4" :src="utility.getImgSrc('ic-account-white.svg')" alt="Profile Image" />
          </v-btn>
        </div>
        <div class="main-header-profile text-left ml-3" style="width: 90px;line-height: 1.25rem;">
          <div class="one-line text-lg word-break-all font-weight-semi-bold">{{ profile.displayName || profile.username }}</div>
          <div v-if="profile.organization" class=" text-gray text-sm font-weight-light one-line word-break-all">{{ profile.organization }}</div>
        </div>
        <div>
          <!-- v-menu -->
          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="ml-3">
                <v-icon large class="header-icon text-white">mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list class="text-left py-0">
              <v-list-item :to="'/dashboard'">
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item>
              <v-divider />
              <v-list-item @click="logout">
                <v-list-item-title>{{ $t('app.logout') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-else class="login-panel d-flex align-center">
        <v-btn
          color="secondary-variant"
          class="text-white ml-8"
          @click="login"
        >
          Log in
        </v-btn>
        <v-btn
          outlined
          :color="$route.name === 'login' || $route.name === 'signup' ? 'secondary-variant':'#FFF'"
          class="ml-6"
          @click="signup"
        >
          Sign up
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import LocaleSwitch from '@/components/LocaleSwitch.vue'
export default {
  components: { LocaleSwitch },
  name: 'LandingHeader',
  data () {
    return {
      drawer: false,
      navigationItems: [ // title, path, icon
        { title: 'About', path: '/about' },
        { title: 'APIs', scrollSelector: '.landing-api' },
        { title: 'Map Maker', scrollSelector: '.landing-portal' },
        { title: 'Data Cube', scrollSelector: '.landing-cube' },
        { title: 'Solutions', scrollSelector: '.landing-showcase' },
        { title: 'Pricing', scrollSelector: '.landing-pricing' }
      ],
      profileImg: null,
      profile: {}
    }
  },
  mounted () {
    const profile = this.sessionManager.getUserProfile()
    if (profile) {
      this.profile = profile
    }
  },
  methods: {
    onNavigationClick (item) {
      if (item.path) {
        this.$router.push(item.path)
      } else if (item.scrollSelector) {
        this.drawer = false
        if (this.$route.name === 'landing') {
          setTimeout(() => {
            const el = document.querySelector(item.scrollSelector)
            if (el !== null) this.$scrollTo(el, { easing: 'ease' })
          }, 100)
        } else {
          sessionStorage.setItem('landingScrollTo', item.scrollSelector)
          this.$router.push({ name: 'landing' })
        }
      }
    },
    login () {
      window.location.href = this.$keycloak.createLoginUrl({ redirectUri: window.location.origin + this.$router.resolve({name: 'dashboard'}).href, locale: this.$i18n.locale })
    },
    signup () {
      window.location.href = this.$keycloak.createRegisterUrl({ redirectUri: window.location.origin + this.$router.resolve({name: 'dashboard'}).href, locale: this.$i18n.locale })
    },
    logout () {
      this.sessionManager.removeUserSession()
      this.profile = {}
      this.$keycloak.logout()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/style/landing-header.scss';
</style>
