import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { Api } from './assets/js/api.js'
import { Utility } from './assets/js/utility.js'
import { SessionManager } from './assets/js/session-manager.js'
import store from './store'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import VueCookies from 'vue-cookies'
import VueScrollTo from 'vue-scrollto'
import authentication from '@/plugins/keycloak-plugin'

Vue.config.productionTip = false

const api = new Api()
const utility = new Utility()
const sessionManager = new SessionManager()

Vue.use(Vuex)
Vue.use(VueScrollTo)
Vue.use(VueCookies, { expire: '1d'})

Vue.use(authentication)


function redirectToLogin (redirectPath) {
  sessionManager.removeUserSession()
  // do not redirect if it's the landing page
  if (router.currentRoute.name === 'landing') return
  window.location.href = Vue.$keycloak.createLoginUrl({ locale: i18n.locale })
  console.log(redirectPath)
}

api.axios.interceptors.request.use(
  config => {
    const token = Vue.$keycloak.token
    if (config.url.startsWith(process.env.VUE_APP_BASE_API_URL) && token) {
      config.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

api.axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  async (error) => {
    const originalConfig = error.config
    switch (error.response.status) {
      case 401:
      case 403:
        if (Vue.$keycloak.refreshToken) {
          if (!originalConfig._retry) {
            originalConfig._retry = true;
            const refreshed = await Vue.$keycloak.updateToken(70).catch(()=>{
              console.warn('Failed to refresh token')
              redirectToLogin(router.currentRoute.fullPath)
            })
            console.log(refreshed ? 'Token refreshed' : console.log('Token not refreshed'))
            originalConfig.headers['Authorization'] = `Bearer ${Vue.$keycloak.token}`
            return api.axios(originalConfig)
          } else {
            redirectToLogin(router.currentRoute.fullPath)
          }
        }
      break;
    }
    return Promise.reject(error.response)
  }
)

const snackbar = new Vuex.Store({
  state: {
    showSnack: false,
    message: null
  },
  mutations: {
    show (state, payload) {
      state.showSnack = true
      state.message = payload.message
    },
    hide (state) {
      state.showSnack = false
    }
  },
  actions: {
    show (context, payload) {
      context.commit('show', payload)
    },
    hide (context) {
      context.commit('hide')
    }
  }
})

Vue.mixin({
  data () {
    return {
      api,
      utility,
      sessionManager,
      snackbar
    }
  }
})

Vue.$keycloak
  .init({ onLoad: 'check-sso' })
  .then(() => {
    new Vue({
      router,
      i18n,
      store,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })
