export class Utility {
  getImgSrc (fullname, isStatic = false) {
    if (fullname) {
      try {
        return isStatic ? `${process.env.VUE_APP_BASE_URL || ''}img/${fullname}` : require(`@/assets/img/${fullname}`)
      } catch (ex) {
        return ''
      }
    }
    return ''
  }

  getImgSrcSet (name, type = 'png', isStatic = false) {
    if (name && type) {
      const x = `${name}@1x.${type}`
      const xx = `${name}@2x.${type}`
      const xxx = `${name}@3x.${type}`
      const xxxx = `${name}@4x.${type}`
      const srcSet = `${this.getImgSrc(x, isStatic)} 1x, ${this.getImgSrc(xx, isStatic)} 2x, ${this.getImgSrc(xxx, isStatic)} 3x, ${this.getImgSrc(xxxx, isStatic)} 4x`
      return srcSet === ' 1x,  2x,  3x,  4x' ? '' : srcSet
    }
    return ''
  }

  getDateString (timeString, locale, showFullYear = false) {
    // timeString Ex. 2022-05-09T10:54:52.016Z
    locale = locale || 'en'
    const now = new Date()
    const d = new Date(timeString)
    if (!showFullYear && now.getFullYear() === d.getFullYear()) {
      return `${d.toLocaleString(locale === 'th' ? 'th-TH' : 'en-US', { month: 'long', day: 'numeric' })}`
    } else {
      return `${d.toLocaleString(locale === 'th' ? 'th-TH' : 'en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`
    }
  }

  getNumericDateString (timeString, locale, includeTime = false) {
    // timeString Ex. 2022-05-09T10:54:52.016Z
    locale = locale || 'en'
    const d = new Date(timeString)
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = (d.getFullYear() + (locale === 'th' ? 543 : 0)) % 100 // get last 2 digits
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    let hour = d.getHours()
    let minute = d.getMinutes()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute

    return [day, month, year].join('/') + (includeTime ? ` ${hour}:${minute}` : '')
  }

  getStartOfDate (dayOffset) {
    dayOffset = dayOffset || 0
    const d = new Date()
    d.setUTCHours(-7,0,0,0)
    d.setDate(d.getDate() + dayOffset)
    return d.toISOString()
  }

  getNumericDateStringForInput (timeString, locale, onlyMonth = false) {
    // timeString Ex. 2022-05-09T10:54:52.016Z
    locale = locale || 'en'
    const d = timeString ? new Date(timeString) : new Date()
    let month = '' + (d.getMonth() + 1)
    let day = '' + d.getDate()
    const year = (d.getFullYear() + (locale === 'th' ? 543 : 0))
    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return (onlyMonth ? [year, month].join('-') : [year, month, day].join('-'))
  }

  getLongTimeString (timeString, locale, longMonth = true) {
    locale = locale || 'en'
    const d = new Date(timeString)
    let hour = d.getHours()
    let minute = d.getMinutes()
    if (hour < 10) hour = '0' + hour
    if (minute < 10) minute = '0' + minute
    return `${d.toLocaleString(locale === 'th' ? 'th-TH' : 'en-US', { month: longMonth ? 'long' : 'short', day: 'numeric', year: 'numeric' })} ${hour}:${minute}`
  }

  getPluralSuffix (amount, locale) {
    return locale === 'en' && parseInt(amount) !== 1 ? 's' : ''
  }

  openLink (e) {
    if (e.target) {
      e.preventDefault()
      e.stopPropagation()
      window.open(e.target.href, '_blank')
    } else {
      window.open(e, '_blank')
    }
  }

  getApiKeyTypeText (type) {
    switch (type) {
      case 'C':
        return 'Credits'
      case 'U':
        return 'Unlimited'
      default:
        return type
    }
  }

  getCreditTypeText (type) {
    switch (type) {
      case 'P':
        return 'Paid'
      case 'B':
        return 'Bonus'
      default:
        return type
    }
  }

  getStorageSizeString (size) {
    if (size >= 1024) return `${parseFloat((size/1024).toFixed(2)).toLocaleString()} GB`
    else return `${size.toLocaleString()} MB`
  }

  flattenRoleArray (roles) {
    // childRoles
    const ar = roles.reduce((role, val) => role.concat([val, ...val.childRoles]), [])
    return ar
  }

  reformatApiUsageByDate (data) {
    const ar = []
    let keys = Object.keys(data)
    let i, count
    /////// show months instead of dates if more than 40 days
    if (keys.length > 40) {
      for (i in keys) {
        count = data[keys[i]]
        const monthKey = keys[i].substring(0,7)
        if (ar[monthKey] === undefined) {
          ar[monthKey] = count
        } else {
          ar[monthKey] += count
        }
      }
      data = ar
    }
    /////// reformat
    keys = Object.keys(data)
    for (i in keys) {
      count = data[keys[i]]
      ar.push({
        name: keys[i].substring(0, 10),
        count
      })
    }
    return ar
  }

  reformatApiUsageByType (data) {
    const ar = []
    const keys = Object.keys(data)
    for (let i in keys) {
      const item = data[keys[i]]
      ar.push({
        name: keys[i],
        ...item
      })
    }
    return ar
  }

  reformatApiUsagePrediction (data) {
    const output = {}
    let keys = Object.keys(data.data)
    for (let i in keys) {
      const count = data.data[keys[i]]
      output[keys[i]] = {
        name: keys[i],
        data: count
      }
    }
    keys = Object.keys(data.predict)
    for (let i in keys) {
      const count = data.predict[keys[i]]
      if (output[keys[i]]) {
        output[keys[i]].predict = count
      } else {
        output[keys[i]] = {
          name: keys[i],
          predict: count
        }
      }
    }
    const outputAr = []
    keys = Object.keys(output)
    for (let i in keys) {
      output[keys[i]].data = (output[keys[i]].data != null)? output[keys[i]].data : null
      output[keys[i]].predict = (output[keys[i]].predict != null)? output[keys[i]].predict : null
      outputAr.push(output[keys[i]])
    }
    return outputAr.sort((a, b) => a.name.localeCompare(b.name))
  }

  validateNumber (e) {
    const reg = /^\d+$/
    if (!reg.test(e.key)) {
      e.preventDefault()
    }
  }

  validateDecimalNumber (e) {
    const reg = /^(?:\d|\.)$/
    if (!reg.test(e.key)) {
      e.preventDefault()
    }
  }

  hasDuplicate (str) {
    if (!str) return false
    const ar = str.split(',')
    return (new Set(ar)).size !== ar.length
  }

  isElementInViewport (el) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  isElementEnteringViewport (el) {
    const rect = el.getBoundingClientRect()
    const elemTop = rect.top + 200 // 200 = buffer
    const elemBottom = rect.bottom
    return elemTop < window.innerHeight && elemBottom >= 0
  }

  saveFile (data, filename) {
    const href = URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }
}
