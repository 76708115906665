import axios from 'axios'

export class Api {
  constructor() {
    this.axios = axios
    this.appBaseUrl = process.env.VUE_APP_BASE_URL
    this.apiBaseUrl = process.env.VUE_APP_BASE_API_URL
    this.apiGeonodeUrl = process.env.VUE_APP_GEONODE_API_URL
    this.config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      timeout: 5000
    }
  }

  // User
  async getUserProfile() {
    return this.axios.get(
      this.apiBaseUrl + 'users/profile',
    )
  }

  async getUserProfileById(userId) {
    return this.axios.get(
      this.apiBaseUrl + 'users/' + userId,
    )
  }

  async updateUserProfile({ userId, displayName, email, organization, groupIds, policyTypeId, roleIds, storageLimit }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/' + userId,
      { displayName, email, organization, groupIds, policyTypeId, roleIds, storageLimit: parseInt(storageLimit) }
    )
  }

  async getUserPaymentInfo({ userId }) {
    return this.axios.get(
      this.apiBaseUrl + 'users/' + userId + '/paymentInfo'
    )
  }

  async updateUserPaymentInfo({ userId, taxId, name, address, postal, tel }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/' + userId + '/paymentInfo',
      { taxId, name, address, postal, tel }
    )
  }

  async updateUserGroup({ userId, groupId }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/' + userId + '/group',
      { groupId }
    )
  }

  async deleteUser({ userId }) {
    return this.axios.delete(
      this.apiBaseUrl + 'users/' + userId
    )
  }

  // User for admin
  async getUsers() {
    return this.axios.get(
      this.apiBaseUrl + 'users',
    )
  }

  async blockUser({ userId, remark }) {
    return this.axios.post(
      this.apiBaseUrl + 'users/' + userId + '/block',
      { remark }
    )
  }

  async unblockUser({ userId, remark }) {
    return this.axios.post(
      this.apiBaseUrl + 'users/' + userId + '/unblock',
      { remark }
    )
  }

  // Admin summary
  async getApiUsageSummary() {
    return this.axios.get(
      this.apiBaseUrl + 'api/usageSummary',
    )
  }

  async getCreditPaidSummary() {
    return this.axios.get(
      this.apiBaseUrl + 'credit/paidSummary',
    )
  }

  async getApiKeysSummary() {
    return this.axios.get(
      this.apiBaseUrl + 'keys/summary',
    )
  }

  async getStorageUsageSummary() {
    return this.axios.get(
      this.apiBaseUrl + 'users/storageUsageSummary',
    )
  }

  async getStorageUsageDetail({uid}) {
    const params = { uid, show_resources: 1 }
    return this.axios.get(
      this.apiGeonodeUrl + 'resource-size/',
      {
        params
      }
    )
  }

  // Admin Dashboard
  async getApiUsageByDate({ dateFrom, dateTo, key, uid }) {
    const params = { dateFrom, dateTo, key, uid }
    return this.axios.get(
      this.apiBaseUrl + 'api/usageByDate',
      {
        params
      }
    )
  }

  async getApiUsageByType({ dateFrom, dateTo, key, uid }) {
    const params = { dateFrom, dateTo, key, uid }
    return this.axios.get(
      this.apiBaseUrl + 'api/usageByType',
      {
        params
      }
    )
  }
  async getApiUsagePrediction({ key, uid }) {
    const params = { key, uid }
    return this.axios.get(
      this.apiBaseUrl + 'api/predictUsage',
      {
        params
      }
    )
  }


  // Statistics
  async getNewAppStatsByDate({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'keys/creationByDate',
      { params }
    )
  }

  async getTopProvinces({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'api/provinceTop10',
      { params }
    )
  }

  async getTopProvincesReport({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'api/provinceTopReport',
      { params, responseType: 'blob' }
    )
  }

  async getTopKeyUsages({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'keys/top10',
      { params }
    )
  }

  async getTopKeyUsagesReport({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'keys/top10Report',
      { params, responseType: 'blob' }
    )
  }

  // API Key
  async getApiKeys() {
    return this.axios.get(
      this.apiBaseUrl + 'keys',
    )
  }

  async getApiKeysByUserId({ userId }) {
    return this.axios.get(
      this.apiBaseUrl + 'keys/user/' + userId,
    )
  }

  async createApiKey({ projectName, type, clients, allowIps, applicationId }) {
    return this.axios.post(
      this.apiBaseUrl + 'keys',
      { projectName, type, clients, allowIps, applicationId }
    )
  }

  async createApiKeyByUserId({ userId, projectName, type, clients, allowIps, applicationId }) {
    return this.axios.post(
      this.apiBaseUrl + 'keys/user/' + userId,
      { uid: userId, projectName, type, clients, allowIps, applicationId }
    )
  }

  async updateApiKey({ key, projectName, type, clients, allowIps, applicationId, expiryDate }) {
    return this.axios.put(
      this.apiBaseUrl + 'keys/' + key,
      { projectName, type, clients, allowIps, applicationId, expiryDate }
    )
  }

  async blockApiKey({ key }) {
    return this.axios.post(
      this.apiBaseUrl + 'keys/' + key + '/block'
    )
  }

  async unblockApiKey({ key }) {
    return this.axios.post(
      this.apiBaseUrl + 'keys/' + key + '/unblock'
    )
  }

  async deleteApiKey({ key }) {
    return this.axios.delete(
      this.apiBaseUrl + 'keys/' + key
    )
  }

  // credits
  async getUserCredits() {
    return this.axios.get(
      this.apiBaseUrl + 'credit/userCredits'
    )
  }

  async getCreditTopupHistory({ month, year, page, perPage }) {
    const params = { month, year, page, perPage }
    return this.axios.get(
      this.apiBaseUrl + 'credit/topupHistory',
      {
        params
      }
    )
  }

  async getCreditTopupHistoryReport({ month, year }) {
    const params = { month, year }
    return this.axios.get(
      this.apiBaseUrl + 'credit/topupHistoryReport',
      {
        params,
        responseType: 'blob'
      }
    )
  }

  async getCreditPaymentReceipt({ refId, admin }) {
    const params = { admin }
    return this.axios.get(
      this.apiBaseUrl + 'credit/paymentReceipt/' + refId,
      {
        params,
        responseType: 'blob'
      }
    )
  }

  async getCreditUsage({ month, year, page, perPage }) {
    const params = { month, year, page, perPage }
    return this.axios.get(
      this.apiBaseUrl + 'credit/getUsage',
      {
        params
      }
    )
  }

  async getCreditUsageReport({ month, year }) {
    const params = { month, year }
    return this.axios.get(
      this.apiBaseUrl + 'credit/getUsageReport',
      {
        params,
        responseType: 'blob'
      }
    )
  }

  async getCreditPaidByDate({ dateFrom, dateTo }) {
    const params = { dateFrom, dateTo }
    return this.axios.get(
      this.apiBaseUrl + 'credit/paidByDate',
      {
        params
      }
    )
  }

  async addCredit({ uid, type, amount, ref1, ref2, refId, remark }) {
    return this.axios.post(
      this.apiBaseUrl + 'credit/add',
      { uid, type, amount, ref1, ref2, refId, remark }
    )
  }

  async getCreditTopupHistories({ month, year, page, perPage }) {
    const params = { month, year, page, perPage }
    return this.axios.get(
      this.apiBaseUrl + 'credit/topupHistories',
      {
        params
      }
    )
  }

  async getCreditTopupHistoriesReport({ month, year }) {
    const params = { month, year }
    return this.axios.get(
      this.apiBaseUrl + 'credit/topupHistoriesReport',
      {
        params,
        responseType: 'blob'
      }
    )
  }

  // groups
  async getGroups() {
    return this.axios.get(
      this.apiBaseUrl + 'users/groups',
    )
  }

  async getGroup({ groupId }) {
    return this.axios.get(
      this.apiBaseUrl + 'users/groups/' + groupId
    )
  }

  async createGroup({ name }) {
    return this.axios.post(
      this.apiBaseUrl + 'users/groups',
      { name }
    )
  }

  async updateGroup({ groupId, name }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/groups/' + groupId,
      { name }
    )
  }

  async deleteGroup({ groupId }) {
    return this.axios.delete(
      this.apiBaseUrl + 'users/groups/' + groupId
    )
  }

  // policy types
  async getPolicyTypes() {
    return this.axios.get(
      this.apiBaseUrl + 'users/policyTypes',
    )
  }

  async getPolicyType({ policyTypeId }) {
    return this.axios.get(
      this.apiBaseUrl + 'users/policyTypes/' + policyTypeId
    )
  }

  async createPolicyType({ name, emailRegex, monthlyBonus, storageLimit }) {
    return this.axios.post(
      this.apiBaseUrl + 'users/policyTypes',
      { name, emailRegex, monthlyBonus: parseInt(monthlyBonus), storageLimit: parseInt(storageLimit) }
    )
  }

  async updatePolicyType({ policyTypeId, name, emailRegex, monthlyBonus, storageLimit }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/policyTypes/' + policyTypeId,
      { name, emailRegex, monthlyBonus: parseInt(monthlyBonus), storageLimit: parseInt(storageLimit) }
    )
  }

  async deletePolicyType({ policyTypeId }) {
    return this.axios.delete(
      this.apiBaseUrl + 'users/policyTypes/' + policyTypeId
    )
  }
  
  // roles
  async getRoles() {
    return this.axios.get(
      this.apiBaseUrl + 'users/roles',
    )
  }

  async getRole({ roleId }) {
    return this.axios.get(
      this.apiBaseUrl + 'users/roles/' + roleId
    )
  }

  async createRole({ name, parentId }) {
    return this.axios.post(
      this.apiBaseUrl + 'users/roles',
      { name, parentId }
    )
  }

  async updateRole({ roleId, name, parentId }) {
    return this.axios.put(
      this.apiBaseUrl + 'users/roles/' + roleId,
      { name, parentId }
    )
  }

  async deleteRole({ roleId }) {
    return this.axios.delete(
      this.apiBaseUrl + 'users/roles/' + roleId
    )
  }

  // permissions
  async getRolePermissions() {
    return this.axios.get(
      this.apiBaseUrl + 'users/rolePermissions',
    )
  }

  async updateRolePermissions(roles) {
    return this.axios.patch(
      this.apiBaseUrl + 'users/rolePermissions',
      roles
    )
  }

  // service price
  async getApiServices() {
    return this.axios.get(
      this.apiBaseUrl + 'api/services',
    )
  }

  async getApiService({serviceId}) {
    return this.axios.get(
      this.apiBaseUrl + 'api/services/' + serviceId,
    )
  }

  async updateApiService({ serviceId, displayName, creditPerRequest }) {
    return this.axios.put(
      this.apiBaseUrl + 'api/services/' + serviceId,
      { displayName, creditPerRequest }
    )
  }

  async getCreditPackages() {
    return this.axios.get(
      this.apiBaseUrl + 'credit/packages',
    )
  }

  async getCreditPackage({packageId}) {
    return this.axios.get(
      this.apiBaseUrl + 'credit/packages/' + packageId,
    )
  }

  async updateCreditPackage({ packageId, name, description, creditAmount, price }) {
    return this.axios.put(
      this.apiBaseUrl + 'credit/packages/' + packageId,
      { name, description, creditAmount, price }
    )
  }

  async requestCreditPayment({ packageId, returnUrl }) {
    return this.axios.post(
      this.apiBaseUrl + 'credit/request2C2PPayment',
      { packageId, returnUrl }
    )
  }

  async checkCreditPaymentTransaction({ invoiceNo }) {
    return this.axios.post(
      this.apiBaseUrl + 'credit/checkPaymentTransaction/' + invoiceNo
    )
  }

  // activity log
  async getActivityLog({ search, activityEventId, dateFrom, dateTo, page, perPage }) {
    const params = { search, activityEventId, dateFrom, dateTo, page, perPage }
    return this.axios.get(
      this.apiBaseUrl + 'activities',
      {
        params
      }
    )
  }

  async getActivityLogReport({ search, activityEventId, dateFrom, dateTo, page, perPage }) {
    const params = { search, activityEventId, dateFrom, dateTo, page, perPage }
    return this.axios.get(
      this.apiBaseUrl + 'activities/report',
      {
        params,
        responseType: 'blob'
      }
    )
  }

  async getActivityEventList() {
    return this.axios.get(
      this.apiBaseUrl + 'activities/events'
    )
  }

  // main layers
  async getMainLayers() {
    return this.axios.get(
      this.apiBaseUrl + 'mainlayers'
    )
  }

  async getMainLayer({ layerId }) {
    return this.axios.get(
      this.apiBaseUrl + 'mainlayers/' + layerId
    )
  }

  async createMainLayer({ name, description, path, service }) {
    return this.axios.post(
      this.apiBaseUrl + 'mainlayers',
      { name, description, path, service }
    )
  }

  async updateMainLayer({ layerId, name, description, path, service }) {
    return this.axios.put(
      this.apiBaseUrl + 'mainlayers/' + layerId,
      { name, description, path, service }
    )
  }

  async deleteMainLayer({ layerId }) {
    return this.axios.delete(
      this.apiBaseUrl + 'mainlayers/' + layerId
    )
  }

  // crop info
  async getCropProductions() {
    return this.axios.get(
      this.apiBaseUrl + 'crops/production'
    )
  }

  async getCropProduction({ cropId }) {
    return this.axios.get(
      this.apiBaseUrl + 'crops/production/' + cropId
    )
  }

  async updateCropProduction({ cropId, name, displayName, price, publishDate }) {
    return this.axios.put(
      this.apiBaseUrl + 'crops/production/' + cropId,
      { id: cropId, name, displayName, price, publishDate }
    )
  }

  // road closures
  async getRouteClose() {
    return this.axios.get(
      this.apiBaseUrl + 'route/close'
    )
  }

  async setRouteClose(data) {
    return this.axios.post(
      this.apiBaseUrl + 'route/close',
      data
    )
  }

  // open data cube
  async getOdcSample () {
    const response = await this.axios.get(this.appBaseUrl + 'odc-sample.json')
    return response
  }
}
