<template>
  <v-app>
    <v-main v-if="isAnon || profile">
      <router-view/>
      <!-- Cookie Banner -->
      <cookie-banner v-if="showCookieBanner" @on-accept="acceptCookies" />
    </v-main>
    <!-- Global Snackbar -->
    <my-snackbar :showSnack="snackbar.state.showSnack" :message="snackbar.state.message" />
  </v-app>
</template>

<script>
import CookieBanner from './components/CookieBanner.vue';
import MySnackbar from './components/MySnackbar.vue';

export default {
  components: {
    CookieBanner,
    MySnackbar
  },
  name: 'App',

  data: () => ({
    registration: null,
    refreshing: false,
    showCookieBanner: false,
    profile: null,
    isProfileLoading: false,
    isAnon: true
  }),
  created () {
    const self = this
    // Listen for our custom event from the SW registration
    document.addEventListener('swUpdated', self.updateAvailable, { once: true })

    // Prevent multiple refreshes
    if ('navigator' in window && 'serviceWorker' in window.navigator) {
      window.navigator.serviceWorker.addEventListener('controllerchange', self.onServiceWorkerControllerchange)
    }

    if (!this.$cookies.get('acceptCookies')) {
      this.showCookieBanner = true
    }
    this.setSiteLanguage()
  },
  mounted () {
    if (this.$keycloak.token) {
      this.getUserProfile()
    } else {
      this.sessionManager.removeUserSession()
    }
    this.isAnon = !this.$keycloak.token
  },
  methods: {
    updateAvailable (event) {
      this.registration = event.detail
      this.refreshApp()
    },
    refreshApp () {
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) {
        return
      }
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    onServiceWorkerControllerChange () {
      if (this.refreshing) {
        return
      }
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    },
    acceptCookies () {
      this.$cookies.set('acceptCookies', true, '10y')
      this.showCookieBanner = false
    },
    setSiteLanguage () {
      // check lang in url
      const queryLocale = this.$route.query.lang
      if (queryLocale) {
        localStorage.setItem('locale', queryLocale)
      }
      if (localStorage.getItem('locale')) {
        this.$i18n.locale = localStorage.getItem('locale')
      } else if (navigator && navigator.language) {
        switch (navigator.language) {
          case 'th':
          case 'th-th':
            this.$i18n.locale = 'th'
            break
          default:
            this.$i18n.locale = 'en'
        }
      } else {
        this.$i18n.locale = 'en'
      }
    },
    // ajax
    getUserProfile () {
      this.isProfileLoading = true
      this.api.getUserProfile()
        .then(response => {
          if (response.data && response.data.id) {
            this.sessionManager.saveUserProfile(response.data)
            this.profile = response.data
          } else {
            console.warn(response)
          }
        }).catch((error) => {
          console.warn(error)
        }).finally(() => {
          this.isProfileLoading = false
        })
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/style/main.scss';
</style>
